import styles from "./jascroLink.module.css";

type Props = {
  theme: "dark" | "light";
};
const JascroLink = ({ theme }: Props) => {
  return (
    <a
      href="https://www.jascro.com"
      target="_blank"
      rel="noopener noreferrer"
      className={`${styles.link} ${
        theme === "dark" ? styles.dark : styles.light
      }`}
    >
      Built by Jascro Technologies
    </a>
  );
};

export { JascroLink };
