import { useRouter } from "next/router";
import { useState } from "react";

import { useGlobalState, useLogOut } from "../../global/hooks";

import styles from "./Navbar.module.css";
import { Routes } from "../../global/constants";
import { JascroLink } from "../jascroLink/jascroLink";

const Navbar = () => {
  const globalState = useGlobalState();
  const router = useRouter();
  const { logout } = useLogOut();
  const [isCopied, setIsCopied] = useState(false);

  const onMyProposalClick = () => {
    router.push(Routes.Proposals);
  };

  const onHomeClick = () => {
    router.push(Routes.Login);
  };

  const handleCopy = () => {
    if (globalState.state.ethPublicKey) {
      navigator.clipboard.writeText(globalState.state.ethPublicKey);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 1000);
    }
  };

  const displayAddress = globalState.state.ethPublicKey
    ? `${globalState.state.ethPublicKey.slice(
        0,
        2
      )}...${globalState.state.ethPublicKey.slice(-4)}`
    : "";

  const isSignedIn =
    globalState.state.isAuthenticated && globalState.state.ethPublicKey;

  return (
    <div
      className={styles.navBar}
      style={{ backgroundColor: isSignedIn ? "#f9fafd" : "#FFFFFF" }}
    >
      <div className={styles.container}>
        <div className={styles.domainName} onClick={onHomeClick}>
          Blockposal.io
        </div>
        {!isSignedIn && <JascroLink theme="dark" />}
        {isSignedIn && (
          <div
            className={`${styles.walletAddress} ${styles.copyable}`}
            onClick={handleCopy}
            title="Click to copy address"
          >
            {isCopied ? "Copied!" : displayAddress}
          </div>
        )}
        {isSignedIn && (
          <button
            className={[styles.navItem, styles.home].join(" ")}
            onClick={onMyProposalClick}
          >
            My Entries
          </button>
        )}
        <div className={styles.profileContainer}>
          {isSignedIn && (
            <button className={styles.navItem} onClick={logout}>
              Sign out
            </button>
          )}
        </div>
      </div>
      <div className={styles.line} />
    </div>
  );
};

export { Navbar };
